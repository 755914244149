import React from "react";
import {CodeBlend} from "./code";

const SampleProjects = () => (
    <>
        <p>
            SQLAPI++ distribution includes {' '}<CodeBlend>samples</CodeBlend>{' '} directory with batch files
            (<CodeBlend>sqltest_mvc.bat</CodeBlend>, {' '}<CodeBlend>sqltest_bcc.bat</CodeBlend>,
            {' '}<CodeBlend>sqltest_mingw.bat</CodeBlend>, {' '}<CodeBlend>sqltest_gcc</CodeBlend>,
            {' '}<CodeBlend>sqltest_ss</CodeBlend>)
            that can be used to build SQLAPI++ sample and test applications.
        </p>
        <p>
            You can also download a ZIP archive with sample SQLAPI++ projects for different Microsoft Visual Studio
            versions:
        </p>
        <p>
            <a href="/Download/MSVSSample.zip">MSVSSample.zip</a>
        </p>
        <p>
            There is also a simple project example for DevC++ (MinGW):
        </p>
        <p>
            <a href="/Download/DEVCPPSample.zip">DEVCPPSample.zip</a>
        </p>
        <p>
            and CodeBlock:
        </p>
        <p>
            <a href="/Download/CodeBlockSample.zip">CodeBlockSample.zip</a>
        </p>
    </>
);

export {SampleProjects};
