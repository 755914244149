import React from "react";
import {CodeSnippet} from "./snippet";

const CodeRef = ({children}) => <code className="code-secondary">{children}</code>;
const CodeBlend = ({children}) => <code className="text-reset">{children}</code>;

const ConnectTutorialCode = ({
                                 className,
                                 database = 'demo',
                                 user = 'guest',
                                 password = 'secret',
                                 client = 'SA_SQLServer_Client'
                             }) => (
    <CodeSnippet className={className}>{`
SAConnection con;
con.Connect(_TSA("${database}"), _TSA("${user}"), _TSA("${password}"), ${client});
            `.trim()}
    </CodeSnippet>
);

const InsertTutorialCode = () => (
    <CodeSnippet>{`
SACommand insert(&con, _TSA("INSERT INTO EMPLOYEES (NAME, AGE) VALUES (:1, :2)"));

insert << _TSA("Tom Patt") << 30L;
insert.Execute();
insert << _TSA("Nick Barry") << 35L;
insert.Execute();
            `.trim()}
    </CodeSnippet>
);

const SelectTutorialCode = () => (
    <CodeSnippet>{`
SACommand select(&con, _TSA("SELECT NAME, AGE FROM EMPLOYEES WHERE AGE > :1"));

select << 30L;
select.Execute();

while(select.FetchNext()) {
    SAString sName = select[1].asString();
    long nAge = select[2].asLong();
    printf("Name: %s, age: %d \\n", sName, nAge);
}            
            `.trim()}
    </CodeSnippet>
);

export {
    CodeRef,
    CodeBlend,
    ConnectTutorialCode,
    InsertTutorialCode,
    SelectTutorialCode
};
