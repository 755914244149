import React from "react";
import { FaWindows, FaLinux, FaApple, FaFreebsd } from "react-icons/fa";

import Layout from "../../components/layout";
import {DownloadHeader as Header} from "../../components/breadcrumb";
import {SampleProjects} from "../../components/sample-projects";
import {KeyHeader} from "../../components/typography";

const pageTitle = "Download";

const TrialDownload = ({icon: Icon, env, filename, children}) => (
    <>

        <div className="col-12 col-md-3 text-center text-info position-relative py-3">
            {Icon &&
            <Icon size={64}/>
            }
            <div className="mb-1">{env}</div>
            <div className="small">
                <a className="text-muted stretched-link" href={`./${filename}`}>{filename}</a>
            </div>
        </div>
        <div className="col-12 col-md-9 align-self-center pt-md-3 pb-3">
            {children}
        </div>
    </>
);

const Page = () => (
    <Layout pageTitle={pageTitle}
            pageNav={<Header/>}
    >
        <KeyHeader className="mt-0">Downloads</KeyHeader>
        <p>
            You can download the latest version of SQLAPI++ library from our site.
            Make sure your testing environment is compatible.
        </p>

        <div className="container-fluid">
            <div className="row">
                {/* Windows */}
                <TrialDownload icon={FaWindows}
                               env="Windows Trial Version"
                               filename="sqlapi_trial.exe"
                >
                    MSVC++, Borland and MingGW g++ configurations are available for trial.
                    <details>
                        <summary>See details</summary>
                        <ul className="small">
                            <li>
                                MSVC++
                                Visual Studio 2010 (x86 and x86-64), 2012 (x86 and x86-64), 2017 (x86 and x86-64), 2019 (x86 and x86-64), 2022 (x86 and x86-64)
                            </li>
                            <li>
                                Borland C++ XE7 (x86 and x86-64), XE8 (x86 and x86-64), 10.2 (x86 and x86-64), 10.3 (x86 and x86-64), 10.4 (x86 and x86-64)
                            </li>
                            <li>
                                MinGW g++ 4.8 (mingw-w64 x86 and x86-64), 8.1 (mingw-w64 x86 and x86-64), 9.2
                            </li>
                        </ul>
                    </details>
                </TrialDownload>

                {/* Linux */}
                <TrialDownload icon={FaLinux}
                               env="Linux Trial Version"
                               filename="sqlapi_trial.tar.gz"
                >
                    Multiple g++ (GNU project C and C++ compiler) configurations are available for trial.
                    <details>
                        <summary>See details</summary>
                        <ul className="small">
                            <li>g++ version 4.1 (x86 and x86-64)</li>
                            <li>g++ version 4.2 (x86 and x86-64)</li>
                            <li>g++ version 4.4 (x86 and x86-64)</li>
                            <li>g++ version 4.6 (x86 and x86-64)</li>
                            <li>g++ version 4.7 (x86 and x86-64)</li>
                            <li>g++ version 4.8 (x86 and x86-64)</li>
                            <li>g++ version 4.9 (x86 and x86-64)</li>
                            <li>g++ version 5.3 (x86 and x86-64)</li>
                            <li>g++ version 6.5 (x86 and x86-64)</li>
                            <li>g++ version 7.2 (x86 and x86-64)</li>
                            <li>g++ version 12.2 (x86 and x86-64)</li>
                        </ul>
                    </details>
                </TrialDownload>

                {/* Apple */}
                <TrialDownload icon={FaApple}
                               env="Mac OS X Trial Version"
                               filename="sqlapi_trial.dmg"
                >
                    Available for clang (x86, x86-64 and arm64) configuration.
                </TrialDownload>

                {/* FreeBSD */}
                <TrialDownload icon={FaFreebsd}
                               env="FreeBSD 12.1 Trial Version"
                               filename="sqlapi_trial_freebsd.tgz">
                    Available for clang (x86 and x86-64) configuration.
                </TrialDownload>

                {/* Solaris 10 x86 */}
                <TrialDownload env="Solaris 10 x86 Trial Version"
                               filename="sqlapi_trial_sol10-x86.tar.Z">
                    Available for Solaris Studio 12 C++ compiler (x86 and x86-64) configuration.
                </TrialDownload>

                {/* Solaris 10 SPARC */}
                <TrialDownload env="Solaris 10 SPARC Trial Version"
                               filename="sqlapi_trial_sol10-sparc.tar.Z">
                    Available for Solaris Studio 12 C++ compiler (32- and 64-bit) configuration.
                </TrialDownload>
            </div>
        </div>

        <KeyHeader>Sample Projects</KeyHeader>
        <SampleProjects/>
    </Layout>
);

export default Page;
