import React from "react";
import * as PropTypes from "prop-types";
import {Link} from "gatsby";
import {MdQuestionAnswer} from "react-icons/md";

const Header = ({children}) => (
    <div className="breadcrumb bg-transparent border-bottom d-flex mb-0">
        {children}
    </div>
);

const Breadcrumb = ({parentTo, parentName, activeName, children}) => (
    <div className="breadcrumb bg-transparent border-bottom mb-0" aria-label="breadcrumb">
        <div className="breadcrumb-item"><Link to={parentTo}>{parentName}</Link></div>
        <div className="breadcrumb-item active" aria-current="page">{activeName}</div>

        {children &&
        <div className="ml-auto">{children}</div>
        }
    </div>
);

const GettingStartedBreadcrumb = ({activeName}) => (
    <Breadcrumb parentTo="/HowTo/" parentName="Getting Started" activeName={activeName}/>
);

const SupportHeader = () => (
    <Header>
        <Link className="mr-3" to="/Support/faq/">FAQ</Link>
        <Link className="mr-3" to="/ApiDoc/">Documentation</Link>
        <div className="d-none d-sm-flex align-items-center ml-auto">
            <div title="Questions?"><MdQuestionAnswer className="mr-2"/></div>
            <a href="mailto:support@sqlapi.com">support@sqlapi.com</a>
        </div>
    </Header>
);

const SupportBreadcrumb = ({activeName}) => (
    <Breadcrumb parentTo="/Support/" parentName="Support" activeName={activeName}/>
);

const DownloadHeader = () => (
    <Header>
        <Link className="mr-3" to="/Order/license/">License Agreement</Link>
        <Link className="mr-3" to="/Download/history/">Release History</Link>
    </Header>
);

const DownloadBreadcrumb = ({activeName}) => (
    <Breadcrumb parentTo="/Download/" parentName="Download" activeName={activeName}/>
);

const OrderHeader = () => (
    <Header>
        <Link className="mr-3" to="/Order/license/">License Agreement</Link>
        <Link className="mr-3" to="/Order/references/">References</Link>
        <div className="d-none d-sm-flex align-items-center ml-auto">
            <div title="Questions?"><MdQuestionAnswer className="mr-2"/></div>
            <a href="mailto:order@sqlapi.com?subject=Order Related Questions">order@sqlapi.com</a>
        </div>
    </Header>
);

const OrderBreadcrumb = ({activeName, children}) => (
    <Breadcrumb parentTo="/Order/" parentName="Order" activeName={activeName}>
        {children}
    </Breadcrumb>
);

Breadcrumb.propTypes = {
    parentTo: PropTypes.string.isRequired,
    parentName: PropTypes.string.isRequired,
    activeName: PropTypes.string.isRequired
};

GettingStartedBreadcrumb.propTypes = {
    activeName: PropTypes.string.isRequired
};

export {
    GettingStartedBreadcrumb,
    SupportHeader,
    SupportBreadcrumb,
    DownloadHeader,
    DownloadBreadcrumb,
    OrderHeader,
    OrderBreadcrumb
};
